/* global global, Office, self, window */

Office.onReady((info) => {
});

function openSCF(event: Office.AddinCommands.Event) {
    const host: string = (Office?.context?.host || 'unknown').toString().toLowerCase();
    const targetUrl: string = `https://scf.siemens.cloud/?from=${host}`;
    switch (event.source.id) {
        case "OpenSCFButton":
            if (Office.context.ui.openBrowserWindow) {
                Office.context.ui.openBrowserWindow(targetUrl);
            } else {
                Office.context.ui.displayDialogAsync(targetUrl, {
                    width: 50,
                    height: 50,
                    displayInIframe: false,
                });
            }
            break;
    }
    event.completed();
}

function getGlobal() {
    return typeof self !== "undefined"
        ? self
        : typeof window !== "undefined"
            ? window
            : typeof global !== "undefined"
                ? global
                : undefined;
}

// The add-in command functions need to be available in global scope
const g = getGlobal() as any;
g.openSCF = openSCF;
